import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

import LogoSource from './assets/logo_purple.png';

import './App.css';

import MediaPlayer from './components/media-player';

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #6b495a;
  display: flex;
  flex-direction: column;
  padding: 2rem;
`;

const Header = styled.header`
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: center;
`; 

const Logo = styled.img`
  width: 25rem;
`;

const Main = styled.main`
  flex: 3;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const App: FunctionComponent = () => (
  <Container>
    <Header>
      <Logo src={LogoSource} />
    </Header>
    <Main>
      <MediaPlayer />
    </Main>
  </Container>
);

export default App;
