import React, { FunctionComponent } from "react";
import styled, { css } from "styled-components";

import { PlayArrow, Stop } from '@material-ui/icons';

const Button = styled.a<{ disabled: boolean }>`
  background-color: #fff;
  width: 160px;
  height: 160px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);

  transition: all 0.2s;

  &:active {
    opacity: 0.4;
    box-shadow: none;
  }

  ${props => props.disabled && css`
    opacity: 0.2;
  `}
`;

export type PlayPauseButtonProps = {
  paused: boolean,
  onClick: () => void,
  disabled?: boolean,
};

const PlayPauseButton: FunctionComponent<PlayPauseButtonProps> = ({ paused, onClick, disabled = false }) => (
  <Button disabled={disabled} onClick={() => !disabled && onClick()}>
    {paused 
      ? <PlayArrow style={{ fontSize: 64, color: '#6b495a' }} />
      : <Stop style={{ fontSize: 64, color: '#6b495a' }} />
    }
  </Button>
);

export default PlayPauseButton;