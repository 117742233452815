import React, { FunctionComponent, useState, useRef, useCallback, useEffect } from "react";
import moment from 'moment';

import PlayPauseButton from "./play-pause-button";

const START_OF_DAWN = moment(1586751930600, 'x');

const calculateAudioCurrentTime = (duration: number) => {
  const offsetSeconds = moment().diff(START_OF_DAWN, 'seconds');

  return offsetSeconds % duration;
}

const MediaPlayer: FunctionComponent = () => {
  const audioRef = useRef<HTMLAudioElement>(null);

  const [sourceUrl, setSourceUrl] = useState<string | null>(null);

  useEffect(() => {
    fetch('https://colininthekitchen.com/metadata.json')
      .then(resp => resp.json())
      .then(data => setSourceUrl(data.url));
  }, []);

  const [canStartPlaying, setCanStartPlaying] = useState(false);
  const [playing, setPlaying] = useState(false);

  const togglePlayback = useCallback(() => {
    if (audioRef.current == null) return;

    if (playing) {
      audioRef.current.pause();
    } else {
      const { duration } = audioRef.current;

      audioRef.current.currentTime = calculateAudioCurrentTime(duration);
      audioRef.current.play();
    }
  }, [playing]);

  return (
    <>
      <PlayPauseButton
        paused={!playing}
        onClick={() => togglePlayback()}
        disabled={!canStartPlaying}
        />
        {sourceUrl != null && (
          <audio
            ref={audioRef}
            preload="auto"
            autoPlay
            loop
            onLoadedData={() => {
              const { duration } = audioRef.current!;

              audioRef.current!.currentTime = calculateAudioCurrentTime(duration);
            }}
            onCanPlayThrough={() => setCanStartPlaying(true)}
            onPlay={() => setPlaying(true)}
            onPause={() => setPlaying(false)}
          >
            <source src={sourceUrl} type="audio/mpeg" />
          </audio>
        )}
    </>
  );
}

export default MediaPlayer;
